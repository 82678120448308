<template>
    <div class="numInputs">
        <input v-for="(n, index) in amount" :class="focusindex===index?'foucs_ac':''" @focus="focus(index)" v-model="arr[index]" :key="index" maxlength="1" @keydown="nextFocus($event, index)"
            ref="numInput" type="password" />
    </div>
</template>
<script>
export default {
  props: {
    amount: {
      default: 6,
      type: Number
    }
  },
  data() {
    return {
      arr: [],
      focusindex: 0
    };
  },
  created() {
    setTimeout(() => {
      this.$refs.numInput[0] && this.$refs.numInput[0].focus();
    }, 0);
  },
  watch: {
    arr(val) {
      this.$emit("input", val.join(""));
    }
  },
  methods: {
    nextFocus($event, index) {
      let key = $event.key;
      console.log('ssssssss',key)
      let teg = /^[0-9]+.?[0-9]*$/;
      console.log('key',key,teg.test(key));
      
      if ((
        key === "e" ||
        key === "E" ||
        key === "-" ||
        key === "+" ||
        key === "." )&&!teg.test(key)
      ) {
        $event.returnValue = false;
        return false;
      } else {
        // 键入时光标跳转
        this.arr[index] &&
          this.$refs.numInput[index + 1] &&
          this.$refs.numInput[index + 1].focus();
        // 删除
        if ($event.keyCode === 8) {
          if (this.arr[index]) {
            this.arr[index] = "";
            this.arr = JSON.parse(JSON.stringify(this.arr));
            this.$refs.numInput[index].focus();
          } else {
            this.$refs.numInput[index - 1] &&
              this.$refs.numInput[index - 1].focus();
          }
        }
        return true;
      }
    },
    focus(i) {
      this.focusindex = i;
    }
  }
};
</script>
<style lang="scss" scoped>
.numInputs {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  input {
    width: 48px;
    height: 68px;
    font-size: 20px;
    color: #383b46;
    line-height: 68px;
    text-align: center;
    background: #f7f8f9;
    border-radius: 4px;
  }
  .foucs_ac {
    width: 46px;
    height: 66px;
    line-height: 66px;
    border: 1px solid #d8b691;
  }
}
</style>