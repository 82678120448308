<template>
<section class="PublicFundProcess">
  <div class="publicfundprocess_cont">
    <a @click="backs" class="title_">
      <span class="iconfont">&#xe665;</span> 基金定投
    </a>
    <div class="details_">
      <div class="details_name">
        <div class="l_details">
          <div class="name_d">{{comp_FundName}} <span class="type_">{{comp_FixState}}</span> </div>
          <div class="timer_ti"><span class="timer">{{infoData.nextFixrequestDate}}</span>将进行新一期定投扣款，请保持账户 {{infoData.bankAccount}} ({{infoData.bankName}}) 资金充足</div>
        </div>
        <div class="r_details">
          <a @click="operate(0)" :class="['modify_', isDisabled? 'disabled':'']">修改</a>
          <a @click="operate(1)" :class="['modify_',isDisabledH?'disabled':'']">{{operateText}}</a>
          <a @click="operate(2)" :class="['stop', !isDisabledP&&isDisabledH? 'disabled':'']">终止</a>
        </div>
      </div>
      <div class="card_details">
        <div class="card_cont_">
          <div class="titles_">累计定投</div>
          <div class="num_crad">{{infoData.allBalance||0}}元</div>
        </div>
        <div class="card_cont_">
          <div class="titles_">{{com_ProtocolPeriodUnit}} 定投</div>
          <div style="color: #CE9B63;" class="num_crad">{{infoData.balance||0}}元</div>
        </div>
        <div class="card_cont_">
          <div class="titles_">已投期数</div>
          <div class="num_crad">{{infoData.reqCount}}期</div>
        </div>
      </div>
    </div>
    <div class="solids_"></div>
    <div style="padding-top:16px;font-weight: 700;height:0px;" class="title_">
      定投记录
    </div>
    <div class="table_list_">
      <div class="table_">
        <div class="tr_ti">
          <div class="ti_1">时间 </div>
          <div class="ti_2">每期定投(元) </div>
          <div class="ti_3">结果 </div>
        </div>
        <div v-for="(item,j) in tds" :key="j" class="td">
          <div class="td_s">
            <div class="td_1">{{item.fixrequestDate}}</div>
          </div>
          <div class="td_2">{{item.balance}}</div>
          <div @click="topageId(item)" :class="item.confirmFlag=='1'?'':'colors_'" class="td_3">{{item.confirmFlag=='0'?'待确认':item.confirmFlag=='1'?'成功':item.confirmFlag=='2'?'成功':'失败'}}</div>
        </div>
      </div>
    </div>
    <Models @showdelte="showdelte" :showmodel="showmodel" @cancels="cancels" @confirms="confirms" @showmodels="showmodel=false">
      <div class="cont_models">
        <div class="titles_ab">确认{{modelstitle}}定投吗？</div>
        <div class="models_txt">
          确认{{modelstitle}} <span style="color:#CE9B63;">{{comp_FundName}}</span>的定投计划？<br /><span v-if="modelstitle=='终止'">终止定投后资产将自动合并到基础账户，不再单独统计。</span>
        </div>
        <div class="inputs__">请输入密码</div>
        <PasswordInput v-model="mesVal" />

      </div>
    </Models>
  </div>
</section>
</template>

<script>
import Models from "@/components/Models";
import PasswordInput from "@/components/PasswordInput";
import {
  statisticFixReq,
  fixReqPage,
  fixModifyPause
  // fixModifyTrade
} from "@/api/mysetcast";

export default {
  name: "DinDetails",

  components: {
    Models,
    PasswordInput
  },
  data() {
    return {
      side: [
        {
          title: "选择卖出基金",
          type: 1
        },
        {
          title: "填写卖出信息",
          type: 2
        },
        {
          title: "输入卖出密码",
          type: 0
        },
        {
          title: "交易结果",
          type: 0
        }
      ],
      showmodel: false, //弹窗
      tds: [],
      mesVal: null,
      infoData: {},
      modelstitle: "暂停",
      page: {
        pageSize: 10,
        pageNum: 1
      }
    };
  },
  computed: {
    isDisabled() {
      let ret = false;
      const fixState = this.infoData.fixState;
      // let { fixState } = this.Comp_Params;
      if (fixState != "A") {
        ret = true;
      }
      return ret;
    },
    isDisabledH() {
      let ret = false;
      const fixState = this.infoData.fixState;
      // let { fixState } = this.Comp_Params;
      if (fixState == "H") {
        ret = true;
      }
      return ret;
    },
    isDisabledP() {
      let ret = false;
      const fixState = this.infoData.fixState;
      // let { fixState } = this.Comp_Params;
      if (fixState == "P") {
        ret = true;
      }
      return ret;
    },
    operateText() {
      let ret = "暂停";
      if (this.isDisabled) {
        ret = "启用";
      }
      return ret;
    },

    // Comp_Params() {
    //   return JSON.parse(this.$route.query.a);
    // },
    comp_FundName() {
      let bbs = JSON.parse(this.$route.query.a);
      return bbs.fundName;
    },
    comp_FixState() {
      const fixState = this.infoData.fixState;
      const status = this.infoData.status;
      console.log('ass',status)
      let ret = "";
      // 协议状态 正常协议修改填A；终止协议填H；暂停协议填P；
      if (status == "1") {
        if (fixState == "A") {
          ret = "正常";
        } else if (fixState == "H") {
          ret = "终止";
        } else if (fixState == "P") {
          ret = "暂停";
        }
      } else if (status == "2") {
        ret = "失败";
      } else if (status == "3") {
        ret = "异常";
      } else if (status == "4") {
        ret = "变更中";
      }

      return ret;
    },
    com_ProtocolPeriodUnit() {
      const {
        protocolPeriodUnit,
        tradePeriod,
        protocolFixDay,
        nextFixrequestDate
      } = this.infoData;
      let ret = "";
      // 协议周期单位
      // 0:月
      // 1:周
      if (protocolPeriodUnit == "0") {
        // 月
        ret = `每月${protocolFixDay}号`;
      } else if (protocolPeriodUnit == "1") {
        // 周
        const weekArr = [
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六"
        ];
        const index = new Date(nextFixrequestDate).getDay();
        if (tradePeriod == "1") {
          // 单周
          ret = `每周${weekArr[index]}`;
        } else if (tradePeriod == "2") {
          // 双周
          ret = `每双周${weekArr[index]}`;
        }
      }
      return ret;
    }
  },
  created() {
    this.Comp_Params = JSON.parse(this.$route.query.a);
    this.statisticFixReq();
    this.fixReqPage();
  },
  methods: {
    showdelte() {
      this.showmodel = false;
    },
    backs() {
      this.$router.go(-1);
    },
    operate(id) {
      let status = this.isDisabled;
      let statusP = this.isDisabledP;
      let statusH = this.isDisabledH;
      let bba = this.infoData;
      let datas = JSON.stringify(bba);
      switch (id) {
        case 0:
          if (status) {
            return;
          }
          this.modelstitle = "修改";
          this.$router.push({
            name: "DinProcessmodify1",
            query: {
              datas
            }
          });

          break;
        case 1:
          this.modelstitle = "暂停";
          if (status) {
            // 跳到修改
            const params = {
              ...this.infoData,
              fixState: "A"
            };
            let aaa = params;
            let datas = JSON.stringify(aaa);
            this.$router.push({
              name: "DinProcessmodify1",
              query: {
                datas
              }
            });
          } else {
            this.showmodel = true;
          }
          // console.log('status',status)
          break;
        case 2:
          {
            this.modelstitle = "终止";
            if (!statusP && statusH) {
              return;
            } else {
              this.showmodel = true;
            }
          }
          break;
        default:
          break;
      }
    },
    cancels() {
      this.showmodel = false;
    },
    confirms() {
      const { fixallotId, scheduledProtocolId, channelSource } = this.infoData;
      let params = {};
      if (this.modelstitle == "暂停") {
        params = {
          fixallotId,
          scheduledProtocolId,
          channelSource,
          fixState: "P",
          password: this.mesVal
        };
      } else if (this.modelstitle == "终止") {
        params = {
          fixallotId,
          scheduledProtocolId,
          channelSource,
          fixState: "H",
          password: this.mesVal
        };
      }
      fixModifyPause(params)
        .then(res => {
          if (res.code == 200) {
            this.statisticFixReq();
            this.fixReqPage();
            this.$message.success(res.data);
            this.showmodel = false;
          } else {
            this.$message.error(res.msg);
            this.showmodel = false;
          }
        })
        .catch(e => {
          this.$message.error(e);
          this.showmodel = false;
        });
    },
    statisticFixReq() {
      const { scheduledProtocolId } = this.Comp_Params;
      let params = {
        scheduledProtocolId
      };
      statisticFixReq(params).then(res => {
        const { code, data } = res;
        if (code == 200) {
          this.infoData = data;
          this.Comp_Params = {
            ...this.Comp_Params,
            fixState: data.fixState
          };
        }
      });
    },
    fixReqPage() {
      const { scheduledProtocolId } = this.Comp_Params;
      let params = {
        scheduledProtocolId
      };
      fixReqPage(params).then(res => {
        const { code, data: { data = [] } } = res;
        if (code == 200) {
          this.tds = data;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  color: #bdc0cb;
}

::-moz-placeholder {
  color: #bdc0cb;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb;
}

.cont_models {
  .titles_ab {
    font-size: 19px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
    font-weight: 700;
    color: #000000;
    margin-bottom: 14px;
  }

  .models_txt {
    font-size: 16px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
    font-weight: 300;
    color: #000000;
    margin-bottom: 16px;
  }

  .inputs__ {
    font-size: 14px;
    font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
    font-weight: 400;
    color: rgba(21, 25, 32, 0.5);
    margin-bottom: 16px;
  }
}

.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 600px;
  display: flex;
  background: #f1f1f1;

  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;

    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }

    .details_ {
      padding: 0px 40px;

      .details_name {
        display: flex;
        justify-content: space-between;

        .l_details {
          .name_d {
            font-size: 20px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #1f1f1f;

            .type_ {
              display: inline-block;
              background: #bdc0cb;
              border-radius: 2px;
              padding: 2px 7px;
              margin-left: 14px;
              font-size: 10px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ffffff;
            }
          }

          .timer_ti {
            margin-top: 10px;
            font-size: 10px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #bdc0cb;

            .timer {
              color: #ce9b63;
              margin-right: 4px;
            }
          }
        }

        .r_details {
          display: flex;
          width: 180px;
          justify-content: space-between;

          a {
            display: inline-block;
            font-size: 14px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #25293d;
          }

          .modify_ {
            width: 48px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border: 1px solid #e0e2e8;
            border-radius: 2px;
          }

          .stop {
            width: 50px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: linear-gradient(120deg, #f7d9b7 0%, #eabf96 100%);
            border-radius: 2px;
            color: #fff;
          }

          .disabled {
            cursor: not-allowed;
            color: #a5a5a5;
          }
        }
      }

      .card_details {
        margin-top: 16px;
        display: flex;

        .card_cont_ {
          margin-right: 24px;
          background: #f3f4f6;
          border-radius: 12px;
          padding: 14px;

          &:first-child {
            width: 189px;
            height: 41px;
          }

          &:nth-child(2) {
            width: 176px;
            height: 41px;
          }

          &:last-child {
            width: 136px;
            height: 41px;
          }

          .titles_ {
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            color: #1f1f1f;
          }

          .num_crad {
            font-size: 20px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #25293d;
          }
        }
      }
    }

    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
      margin: 15px 40px;
    }

    .table_list_ {
      padding: 0px 40px;

      .table_ {
        margin-top: 22px;

        .tr_ti {
          display: flex;
          align-items: center;

          div {
            height: 59px;
            font-size: 12px;
            color: #1f1f1f;
            background: #f3f4f6;
            line-height: 60px;
            border-top: 1px solid #f3f4f6;
            border-left: 1px solid #ffffff;
            text-indent: 2em;

            &:first-child {
              border-left: 1px solid #f3f4f6;
            }

            &:last-child {
              border-right: 1px solid #f3f4f6;
            }
          }

          .ti_1 {
            width: 399px;
          }

          .ti_2,
          .ti_3 {
            width: 223px;
          }
        }

        .td {
          display: flex;
          align-items: center;

          &:last-child {
            border-bottom: 1px solid #f3f4f6;
          }

          div {
            height: 59px;
            font-size: 12px;
            color: #1f1f1f;
            line-height: 60px;
            border-top: 1px solid #f3f4f6;
            border-left: 1px solid #f3f4f6;
            text-indent: 2em;

            &:first-child {
              border-left: 1px solid #f3f4f6;
            }

            &:last-child {
              border-right: 1px solid #f3f4f6;
            }
          }

          .td_s {
            width: 399px;
            position: relative;

            .td_1 {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .show_models {
              width: 200px;
              min-height: 30px !important;
              height: 0px;
              padding: 4px;
              text-indent: 0em !important;
              background: #fff;
              border: 1px solid #f1f1f1;
              position: absolute;
              left: 120px;
              top: 0px;
              z-index: 999;
              line-height: 20px !important;
              box-shadow: 0px 2px 4px 0px #edf1f7;
            }
          }

          .td_2,
          .td_3 {
            width: 223px;
          }
          .colors_ {
            color: #e45247;
          }
        }
      }
    }
  }
}
</style>
